body {
  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
}

.about-container {
  background: #F2F2F2;
  min-height: 100vh; /* Ensure container covers full viewport height */
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.about-header {
  text-align: center;
  margin-bottom: 30px;
}

.about-header h1 {
  font-size: 36px;
  margin: 0;
}

.slogan {
  font-size: 20px;
  margin-top: 10px;
  font-style: italic;
  text-align: center;
}

.about-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  /* Ensure the content section takes full width and adjusts its layout */
}

.outer-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Make the outer container circular */
  padding: 20px; /* Add padding to create the outer circle effect */
  background-color: #FFFFFF;
  box-shadow: 0px 0px 24.1px -2px #00000073;
}

.inner-circle {
  border-radius: 50%; /* Keep the inner container circular */
  overflow: hidden;
  width: 250px; /* Adjust width as needed */
  height: 250px; /* Adjust height as needed */
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  max-width: 600px;
  text-align: left;
  margin-left: 50px; /* Ensure text doesn't overlap the image */
}

.text-container p {
  font-size: 18px;
  line-height: 1.6;
  margin-top: 10px;
}

.styled-paragraph {
  background: #FFFFFF;
  border-radius: 36px;
  box-shadow: 0px 0px 5.4px 0px #00000014;
  padding: 20px; /* Add some padding for better text alignment */
  margin: 20px 0; /* Add some margin to separate from other elements */
  position: relative; /* To position the element accurately */
  opacity: 1; /* Ensure the element is visible */
}

.divider {
  border: none;
  border-top: 2px solid #555;
  margin: 20px 0;
}

/* Styles for the list section */
.details-list-container {
  display: flex;
  justify-content: center; /* Center the list horizontally */
  margin-top: 20px; /* Add some space above the list */
}

.details-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 600px;
  width: 100%; /* Ensure the list takes up the available width */
}

.details-list li {
  display: flex; /* Flexbox to align items */
  justify-content: flex-start; /* Align labels and values to the start */
  font-size: 18px;
  margin-bottom: 10px;
}

.details-list li strong {
  min-width: 150px; /* Fixed width for the labels */
  margin-right: 10px; /* Add space between label and value */
  font-weight: bold;
}

.details-list li a {
  /* color: #ff6600; */
  text-decoration: none;
}

.details-list li a:hover {
  text-decoration: underline;
}

.corner-element {
  width: 150px;
  height: 150px;
  border: 20px solid #2C2C2C12;
  border-radius: 50%; /* Make the element circular */
  position: fixed; /* Use fixed positioning */
  opacity: 0.5; /* Set opacity for visibility */
  z-index: 1; /* Ensure it's above other content */
  background-color: rgba(44, 44, 44, 0.1); /* Light background color for visibility */
}

.corner-element.top-left {
  top: 20px;
  left: 40px;
  margin: 20px; /* Add some margin from the edges */
}

.corner-element.top-right {
  top: 0;
  right: 0;
  margin: 20px; /* Add some margin from the edges */
}
