/* educationInfoUpload.css */

/* General Container */
.education-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Heading and Subtitle */
  .education-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .education-subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Rows */
  .education-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .education-row.single-field {
    justify-content: center;
  }
  
  /* Fields */
  .education-field {
    flex: 1;
    margin-right: 10px;
  }
  
  .education-field:last-child {
    margin-right: 0;
  }
  
  .education-field label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  .education-field input,
  .education-field select,
  .education-field textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc; /* Same border color as inputs */
    border-radius: 4px;
  }
  
  .date-fields {
    display: flex;
    gap: 10px;
  }
  
  .date-fields select {
    flex: 1;
  }
  
  /* Textarea */
  .education-field textarea {
    resize: vertical;
  }
  
  /* Buttons */
  .add-education-button {
    text-align: right;
    margin-bottom: 20px;
  }
  
  .add-education-button button {
    display: flex;
    align-items: center;
    background-color: white;
    color: black;
    border: 1px solid #ccc; /* Same border color as inputs */
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-education-button .icon {
    margin-right: 8px;
  }
  
  .tips-section {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tips-section button {
    background-color: white;
    color: black;
    border: 1px solid #ccc; /* Same border color as inputs */
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .tips-section .icon {
    color: #FFA500;
  }
  
  .tips-section p {
    margin: 0;
  }
  
  /* Navigation Buttons */
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .back-button,
  .next-button {
    padding: 10px 20px;
    border: 2px solid;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .back-button {
    background-color: white;
    color: red;
    border-color: red;
  }
  
  .next-button {
    background-color: white;
    color: blue;
    border-color: blue;
  }
  /* Save Button Styling */
.save-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #28a745; /* Green color for Save */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.save-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.save-button:active {
  background-color: #1e7e34; /* Even darker green when clicked */
  transform: scale(0.98); /* Slightly shrink on click */
}

.save-button .icon {
  margin-right: 8px;
}

.save-education-button {
  text-align: center;
  margin-top: 20px;
}
