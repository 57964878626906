.experience-card {
    display: inline-block;
    width: 70%;
    justify-content: center;
    margin: 0 0 30px 0;
    padding: 35px 40px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
    
  }
  
  .experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .experience-title {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .experience-title .icon {
    margin-right: 8px;
  }
  
  .experience-actions {
    display: flex;
    align-items: center;
  }
  
  .settings-icon {
    margin-right: 10px;
  }
  
  .collapse {
    display: none;
  }
  
  .collapse.show {
    display: block;
  }
  
  .card-body {
    padding: 10px;
  }
  
  .form-control {
    margin-bottom: 10px;
  }
  
  .button--grey {
    background-color: #f0f0f0;
    border: none;
    color: #333;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button--add-section-item {
    display: flex;
    align-items: center;
  }
  
  .icon-circle {
    border-radius: 50%;
    display: inline-block;
    padding: 5px;
    background-color: #f0f0f0;
    margin-right: 8px;
  }
  
  .icon {
    font-size: 1.2rem;
  }
  
  .alert {
    color: #007bff;
  }
  
  .mt-3 {
    margin-top: 1rem;
  }
  
  .mt-4 {
    margin-top: 1.5rem;
  }
  
  .text-center {
    text-align: center;
  }
  /* Existing CSS remains the same */

.form-control {
  margin-bottom: 10px;
  padding: 6px 12px;
}

input[type="file"] {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 6px;
}

input[type="file"]::-webkit-file-upload-button {
  background: #f0f0f0;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
}

input[type="file"]:hover::-webkit-file-upload-button {
  background: #e0e0e0;
}

input[type="file"]:focus {
  outline: none;
}

input[type="file"].form-control {
  width: 100%;
  box-sizing: border-box;
}
