/* General Container */
.skills-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Heading and Subtitle */
.skills-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
}

.skills-subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
}

/* Skills Fields */
.skills-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.skills-field {
    flex: 1;
    margin-right: 10px;
}

.skills-field:last-child {
    margin-right: 0;
}

.skills-field label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
}

.skills-field input,
.skills-field select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Add Another Skill Button */
.add-skill-button {
    text-align: right;
    margin-bottom: 20px;
}

.add-skill-button button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

/* Navigation Buttons */
.navigation-buttons {
    display: flex;
    justify-content: space-between;
}

.back-button,
.next-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.back-button {
    background-color: #f44336;
    color: white;
    border: 1px solid #f44336;
}

.next-button {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
}
/* Save Button Container */
.save-skill-button {
    margin-top: 10px;
    text-align: right;
  }
  
  /* Save Button */
  .save-skill-button button {
    padding: 4px 8px; /* Smaller padding for a compact button */
    font-size: 0.8rem; /* Smaller font size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4CAF50; /* Green background for save button */
    color: white;
  }
  
  .save-skill-button button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .save-skill-button button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  