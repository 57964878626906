/* General Container */
.summary-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Heading and Subtitle */
.summary-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
}

.summary-subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
}

/* Text Editor */
.text-editor {
    margin-bottom: 20px;
}

.text-editor textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    resize: vertical;
}

/* Navigation Buttons */
.navigation-buttons {
    display: flex;
    justify-content: space-between;
}

.back-button,
.next-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.back-button {
    background-color: #f44336;
    color: white;
    border: 1px solid #f44336;
}

.next-button {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
}
