/* Container for the forget password page */
.forget-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
  }
  
  /* Title at the center top */
  .forget-password-title {
    font-size: 2rem;
    margin-bottom: 200px;
  }
  
  /* Content row for image and form */
  .forget-password-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px; /* Adjusted width to accommodate larger image */
    gap: 20px;
  }
  
  /* Image styling */
  .auth-image {
    max-width: 100%;
    height: auto;
    width: 500px; /* Increased width for larger image */
  }
  
  /* Form container */
  .forget-password-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px; /* Adjust to match your design */
  }
  
  /* Form subheading */
  .form-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  /* Form description paragraph */
  .form-description {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  /* Labels */
  .form-label {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  
  /* Input fields */
  .form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  /* Buttons */
  .form-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
  
  /* Error and Success messages */
  .form-error, .form-success {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .form-error {
    color: red;
  }
  
  .form-success {
    color: green;
  }
  