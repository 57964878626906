.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    /* background-color: #f8f9fa; */
    margin-top: 3px;
  }
  
  .navbar-name {
    font-family: 'Poppins', sans-serif;
    font-size: 40.33px;
    font-weight: 500;
    /* line-height: 68px; */
    text-align: left;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
  }
  
  .navbar-links a {
    margin: 0 10px;
    text-decoration: none;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  
  
  .navbar-links span {
    color: #ccc;
    margin: 0 10px;
  }
  
  .navbar-links a:hover {
    color: #007bff;
  }
  