.home-container {
    background: #2C2C2C;
    color: white;
    min-height: 100vh; /* Minimum height of the container is the full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 20px; */
    box-sizing: border-box;
  }
  
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 50px 0;
  }
  
  .outer-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Make the outer container circular */
    padding: 20px; /* Add padding to create the outer circle effect */
    background-color: #343333; /* Color for the outer circle */
    box-shadow: 0px 0px 26.7px 10px #0000008C; /* Apply shadow to the outer circle */
  }
  
  .image-container {
    border-radius: 50%; /* Keep the image circular */
    overflow: hidden;
    width: 250px; /* Increased width */
    height: 250px; /* Increased height */
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container */
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    text-align: left; /* Align text to the left */
    margin-left: 50px; /* Ensure text doesn't overlap the image */
  }
  
  h1 {
    font-size: 40px;
    margin: 10px 0;
  }
  
  h2 {
    font-size: 35px;
    margin: 10px 0;
  }
  
  p {
    /* font-size: 18px; */
    margin: 20px 0;
    font-family: "Poppins", sans-serif;
font-size: 18px;
font-weight: 400;
/* line-height: 36px; */
text-align: left;

  }
  
  /* Apply styles to the Get Started button */
  .get-started-btn {
    width: 224px; /* Set width */
    height: 68px; /* Set height */
    background-color: #2C2C2C;
    color: white;
    padding: 10px 20px; /* Optional padding, can be adjusted */
    border: 2px solid #0549FF; /* Border style */
    border-radius: 100px; /* Rounded top-left corner */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Shadow effect */
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
    opacity: 1; /* Ensure button is visible */
    text-align: center; /* Center text */
  }
  
  /* .get-started-btn:hover {
    background-color: #ffffff;
  } */
  
  .footer {
    width: 100%;
    /* text-align: center; */
    margin-bottom: 20px;
  }
  
  hr {
    width: 100%;
    border: 0.5px solid #555;
  }
  
  .social-media a {
    color:#FFFFFF80;

    margin: 0 15px;
    text-decoration: none;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    padding-left: 20px;
  }
  
  .social-media a:hover {
    color: #ff6600;
  }
  