/* Wrapper to position background circles */
.homepage-wrapper {
    position: relative; /* Needed to position background circles absolutely */
  }
  
  /* Overall container for the homepage */
  .homepage-content {
    display: flex;
    justify-content: space-between; /* Image on the right, content on the left */
    align-items: center; /* Vertically center both image and content */
    padding: 20px;
    margin-top: 50px;
    position: relative; /* Ensure the circles are positioned relative to this */
  }
  
  /* Text content styling */
  .text-content {
    flex: 1;
    padding-right: 20px; /* Space between text and image */
    padding-left: 50px;
  }
  
  .heading {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .paragraph {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
  }
  
  /* Image content styling */
  .image-content {
    flex: 1;
    display: flex;
    justify-content: end; /* Aligns the image to the right */
  }
  
  /* Image styling */
  .home-image {
    width: 90%;
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Background Circles */
  .background-circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allow clicks to pass through */
    z-index: -1; /* Place behind the main content */
  }
  
  .circle {
    position: absolute;
    border-radius: 50%;
    width: 26px; /* Smaller size for circles */
    height: 26px;
  }
  
  .circle.blue1 {
    background: #FFC8C0;
    top: 13%;
    left: 1.5%;
  }
  
  .circle.blue2 {
    background: #FFC8C0;
    top: 35%;
    left: 14.5%;
  }
  .circle.blue3 {
    background: #FFC8C0;
    top: 45.5%;
    left: 3.9%;
  }

  .circle.blue4 {
    background: #FFC8C0;
    top: 20.5%;
    left: 73%;
  }

  .circle.blue5 {
    background: #FFC8C0;
    top: 65%;
    left: 50%;
  }

  .circle.blue6 {
    background: #C0EAFF;
    ;
    top: 23.5%;
    left: 90%;
  }

  .circle.red {
    background: red;
    top: 20%;
    left: 30%;
  }
  
  .circle.green {
    background: lightgreen;
    top: 30%;
    left: 50%;
  }
  
  .circle.yellow {
    background: lightyellow;
    top: 40%;
    left: 70%;
  }
  
  .circle.purple {
    background: #C0EAFF;
    top: 80%;
    left: 45%;
  }
  
  .circle.orange {
    background: #C0EAFF;
    ;
    top: 95%;
    left: 5%;
  }
  
  .circle.cyan {
    background: cyan;
    top: 70%;
    left: 60%;
  }
  
  .circle.magenta {
    background: magenta;
    top: 80%;
    left: 80%;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 1200px) {
    .homepage-content {
      flex-direction: row; /* Remain in a row for large screens */
    }
  }
  
  @media screen and (max-width: 768px) {
    .homepage-content {
      flex-direction: column; /* Stack content and image vertically on tablets */
      align-items: center; /* Center align text and image */
      text-align: center; /* Center text */
    }
  
    .text-content {
      padding-right: 0; /* Remove right padding on smaller screens */
    }
  
    .home-image {
      max-width: 80%; /* Reduce image size for smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .heading {
      font-size: 28px; /* Smaller font size for headings */
    }
  
    .paragraph {
      font-size: 16px; /* Smaller font size for paragraph */
    }
  
    .home-image {
      max-width: 100%; /* Full width on very small screens */
    }
  }
  