/* Ensure the navbar has a background and spacing */
.navbar {

    margin-left: 50px;
    margin-right: 50px;
  }
  
  /* Style for the brand container */
  .navbar-brand {
    display: flex;
    align-items: center; /* Align items vertically centered */
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-right: 20px; /* Space between logo and brand title */
  }
  
  /* Style for the Portfolio Builder title */
  .brand-title {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-left: 10px; /* Space between logo and text */
  }
  
  /* Style for the button */
  .navbar-nav .btn-primary {
    width: 170px;
    height: 40px;
    border-radius: 8px 0 8px 0;
    opacity: 1;
    display: flex;
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-left: 10px; /* Space between button and other items */
  }
  
  /* General style for nav links */
  .navbar-nav .nav-link {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    margin: 0 10px; /* Space between nav items */
  }
  
  /* Ensure proper alignment of nav items */
  .navbar-nav {
    display: flex;
    align-items: center;
    margin-left: auto; /* Push items to the right */
  }
  
  /* Optional: Adjust visibility and placement for smaller screens */
  @media (max-width: 992px) {
    .navbar-nav .btn-primary {
      margin-left: 0;
      width: auto;
    }
  }
  

