/* Container for the entire signup page */
.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
  }
  
  /* Title */
  .signup-title {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 600;
  }
  
  /* Content row for image and form */
  .signup-content {
    display: flex;
    align-items: center;
    padding: 40px;
  }
  
  /* Image styling */
  .auth-image {
    /* max-width: 100%; */
    width: 50%;
    height: 150%;
  }
  
  /* Form container */
  .signup-form {
    display: flex;
    flex-direction: column;
    margin-left: 15%;
  }
  
  /* Form subheading */
  .form-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  /* Labels */
  .signup-label {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  /* Input fields */
  .signup-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
  }
  
  /* Checkbox for terms and conditions */
  .signup-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .signup-checkbox input {
    margin-right: 10px;
  }
  
  /* Buttons */
  .signup-btn, .google-btn {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .signup-btn {
    background-color: #007bff;
    color: #fff;
  }
  
  .google-btn {
    background-color: #db4437;
    color: #fff;
  }
  
  /* Error and Success messages */
  .signup-error, .signup-success {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .signup-error {
    color: red;
  }
  
  .signup-success {
    color: green;
  }
  
  /* Link to login */
  .signup-login-link {
    text-align: center;
  }
  
  .signup-login-link a {
    color: #007bff;
    text-decoration: none;
  }
  
  .signup-login-link a:hover {
    text-decoration: underline;
  }
  