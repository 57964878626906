/* Container */
.personal-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  
  /* Heading */
  .personal-info-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  /* Subtitle */
  .personal-info-subtitle {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  
  /* Universal row width */
  .row-container {
    width: 100%;
    max-width: 900px; /* Fixed row width */
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  /* Image Row */
  .image-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
  }
  
  /* Image Picker */
  .image-picker-container {
    display: flex;
    justify-content: center;
  }
  
  .image-picker {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }
  
  .camera-icon {
    font-size: 3rem;
    color: #aaa;
  }
  
  .selected-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .image-input {
    display: none;
  }
  
  /* Name and Email Columns */
  .name-email-col {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start; /* Align labels to the start */
  }
  
  .form-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  /* Phone and Address Row (Wider Fields) */
  .phone-address-row,
  .pob-marital-row,
  .nationality-gender-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: 900px;
  }
  
  /* Ensure equal width for all columns */
  .phone-col,
  .address-col,
  .city-col,
  .dob-col,
  .pob-col,
  .marital-col,
  .nationality-col,
  .gender-col {
    flex: 1; /* Ensures all fields have equal width */
  }
  
  /* Form Row */
  .form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    max-width: 900px;
  }
  
  /* Form Buttons */
  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    max-width: 900px;
  }
  
  .back-btn,
  .next-btn {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-btn {
    background-color: #ccc;
    color: #000;
  }
  
  .next-btn {
    background-color: #007bff;
    color: #fff;
  }
  /* Date of Birth Inputs */
.dob-inputs {
    display: flex;
    gap: 10px; /* Space between day, month, and year */
    width: 100%;
    
  }
  
  .dob-input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 30%; /* Each input takes up 30% of the row width */
    text-align: center; /* Center the placeholder and the value */
    width: 100%;
  }
  