.summary-card {
  display: inline-block;
  width: 70%;
  justify-content: center;
  margin: 0 0 30px 0;
  padding: 35px 40px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.summary-title {
  font-size: 18px;
  font-weight: bold;
  color: #495057;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
}

.settings-icon {
  margin-right: 20px;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.btn-group .btn {
  margin-left: 10px;
}

/* Styling for form inputs */
form .form-group,
form .row {
  margin-bottom: 20px;
}

form label {
  margin-bottom: 5px;
  display: block;
  cursor: default;
}

form input,
form select,
form textarea {
  width: 100%;
  background-color: #fff;
  border: 2px solid #eaeaea;
  border-radius: 3px;
  padding: 0 14px 1px;
  font-size: 16px;
max-lines: 5;
}

form input,
form select {
  height: 48px;
}

/* Textarea with increased height */
form textarea {
  height: 200px; /* Adjusted height */
  padding: 10px 14px;
}

.btn-group .btn,
.btn-primary {
  padding: 10px 20px;
}

/* Parent container to center content vertically and horizontally */
.center-container {
  display: flex;
  justify-content: center;
}

/* Add another Skill button styling */
.button--grey {
  color: #4c4c4c;
  cursor: pointer;
  background: #f1f1f1;
  transition: all 0.5s ease;
}

.button--add-section-item {
  width: 100%;
  border: none;
  font-size: 15px;
  outline: none;
  position: relative;
  height: 50px;
  border-radius: 7px;
}

.icon-circle {
  margin-right: 8px;
}
