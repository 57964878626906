/* servicesUpload.css */

/* Container */
.services-container {
  
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Heading and Subtitle */
  .services-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .services-subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Service Row */
  .service-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .service-field {
    flex: 1;
    margin-right: 10px;
  }
  
  .service-field:last-child {
    margin-right: 0;
  }
  
  .service-field label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  .service-field input,
  .service-field textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Buttons */
  .add-service-button {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .add-service-button button {
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-service-button .icon {
    margin-right: 8px;
  }
  
  /* Navigation Buttons */
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .back-button,
  .next-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .back-button {
    background-color: #f44336;
    color: white;
  }
  
  .next-button {
    background-color: #4CAF50;
    color: white;
  }
/* Save Button Container */
.save-service-button {
  margin-top: 10px;
  text-align: right;
}

/* Save Button */
.save-service-button button {
  padding: 4px 8px; /* Smaller padding for a compact button */
  font-size: 0.8rem; /* Smaller font size */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #4CAF50; /* Green background for save button */
  color: white;
}

.save-service-button button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.save-service-button button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
