/* General Container */
.experience-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Heading and Subtitle */
.experience-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
}

.experience-subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
}

/* Rows */
.experience-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.experience-row.single-field {
    justify-content: center;
}

/* Fields */
.experience-field {
    flex: 1;
    margin-right: 10px;
}

.experience-field:last-child {
    margin-right: 0;
}

.experience-field label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
}

.experience-field input,
.experience-field select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.date-fields {
    display: flex;
    gap: 10px;
}

.date-fields select {
    flex: 1;
}

/* Buttons */
.add-experience-button {
    text-align: left;
    margin-bottom: 20px;
}

.add-experience-button button {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.add-experience-button .icon {
    margin-right: 8px;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
}

.back-button,
.next-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.back-button {
    background-color: #f44336;
    color: white;
    border: 1px solid #f44336;
}

.next-button {
    background-color: #4caf50;
    color: white;
    border: 1px solid #4caf50;
}

/* Save Button */
/* Save Button Container */
.save-button {
    text-align: right;
    margin-top: 10px;
}

/* Save Button */
.save-button button {
    padding: 3px 8px; /* Smaller padding for a compact button */
    font-size: 0.8rem; /* Smaller font size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4CAF50; /* Green background for save button */
    color: white;
}

.save-button button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.save-button button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}


/* Horizontal Divider */
.experience-divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
}
  /* Save Button Styling */
  .save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #28a745; /* Green color for Save */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .save-button:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  .save-button:active {
    background-color: #1e7e34; /* Even darker green when clicked */
    transform: scale(0.98); /* Slightly shrink on click */
  }
  
  .save-button .icon {
    margin-right: 8px;
  }
  
  .save-education-button {
    text-align: center;
    margin-top: 20px;
  }
  