
/* General page structure */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
}

.login-title {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;
}

/* Content Row */
.login-content {
    display: flex;
    align-items: center;
    padding: 40px;
    width: 80%;
}
.signup-content {
    display: flex;
    align-items: center;
    padding-top: 20px;
    /* padding: 40px; */
}
.form-title {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}
.signup-title{
    margin-top: 70px;
}

.signup-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    width: 80%;
}

/* Image section */
.auth-image {
    width: 40%;
    height: 150%;
}

/* Form section */
.login-form {
    display: flex;
    flex-direction: column;
    margin-left: 15%;
}

.login-label {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
}

.login-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
}


.login-forgot-remember {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

/* Forgot password link */
.forgot-password-link {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
    justify-content: end;

    
}

.forgot-password-link:hover {
    text-decoration: underline;
}

.login-checkbox {
    display: flex;
    align-items: center;
    height: 30px;
    width: 30px;
    
}

.login-checkbox label {
    margin-left: 10px;
}

.login-btn {
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
}

.google-btn {
    background-color: #db4437;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

.login-create-account {
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
}

.login-create-account a {
    color: #007bff;
    text-decoration: none;
}

.login-create-account a:hover {
    text-decoration: underline;
}
