/* projectsUpload.css */

/* Container */
.projects-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Heading and Subtitle */
  .projects-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .projects-subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Project Row */
  .project-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .project-field {
    flex: 1;
    margin-right: 10px;
  }
  
  .project-field:last-child {
    margin-right: 0;
  }
  
  .project-field label {
    display: block;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
  
  .project-field input,
  .project-field textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Buttons */
  .add-project-button {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .add-project-button button {
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-project-button .icon {
    margin-right: 8px;
  }
  
  /* Navigation Buttons */
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .back-button,
  .next-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .back-button {
    background-color: #f44336;
    color: white;
  }
  
  .next-button {
    background-color: #4CAF50;
    color: white;
  }
 /* Save Button Styling */
 .save-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #28a745; /* Green color for Save */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.save-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.save-button:active {
  background-color: #1e7e34; /* Even darker green when clicked */
  transform: scale(0.98); /* Slightly shrink on click */
}

.save-button .icon {
  margin-right: 8px;
}

.save-education-button {
  text-align: center;
  margin-top: 20px;
}
