.build-portfolio-background {
    /* Full height of the viewport */
    min-height: 100vh; /* Ensure the background covers at least the full height of the viewport */
  
    /* Diagonal linear gradient background */
    background: linear-gradient(
      135deg, /* Diagonal direction */
      #4E79EE 0%, /* Start color */
      #FF6D66 100% /* End color */
    );
  
    /* Optional: Add padding or margin as needed */
    padding: 20px;
  }
  